import React from 'react';
import minimatch from 'minimatch';

export type WithFactoryProps<TPops extends any> = { readonly cLocation?: string } & TPops;

export interface FactoryMatcher {
    readonly matcher: string;
    readonly render: (props: any) => any;
}

export interface FactoryProps<TProps> {
    registrations: Array<FactoryMatcher>;
    register: <TProps>(matcher: string | Array<string>, render: (props: WithFactoryProps<TProps>) => any) => void;
    displayName?: string;
    cLocation?: string;
}

export interface ComponentFactory<TProps> extends FactoryProps<TProps> {
    (props?: TProps): React.ReactElement<WithFactoryProps<TProps>>;
}

export const withFactory = <TProps extends any>(TargetComponent: React.ComponentType<WithFactoryProps<TProps>>) => {
    //* cLocation default value = '/'

    const factory: ComponentFactory<WithFactoryProps<TProps>> = (props?: any) => {
        const { cLocation = '/', ...rest } = props || {};

        const matcherIndex = factory.registrations.map((reg) => reg.matcher).findIndex((v) => minimatch(cLocation, v));

        if (matcherIndex >= 0 && factory.registrations[matcherIndex]) {
            const { matcher, render } = factory.registrations[matcherIndex];

            return render({ ...rest, cLocation, matcher });
        }

        return <TargetComponent {...rest} cLocation={cLocation} matcher="" />;
    };

    factory.registrations = [];

    factory.register = (matcher, render) => {
        if (Array.isArray(matcher)) {
            matcher.forEach((single) => factory.registrations.push({ matcher: single, render }));
        } else {
            factory.registrations.push({ matcher, render });
        }
    };

    factory.cLocation = '/';
    factory.displayName = `${TargetComponent.name}Factory`;

    return factory;
};

export interface FuncFactory<TProps> extends FactoryProps<TProps> {
    (props?: WithFactoryProps<TProps>): any;
}

export const withFuncFactory = <TProps extends any>(
    renderer: (props: TProps) => any
): FuncFactory<WithFactoryProps<TProps>> => {
    const factory: FuncFactory<WithFactoryProps<TProps>> = (props?: any) => {
        const { cLocation = '/', ...rest } = props || { cLocation: '/' };

        const matcherIndex = factory.registrations.map((reg) => reg.matcher).findIndex((v) => minimatch(cLocation, v));

        if (matcherIndex >= 0 && factory.registrations[matcherIndex]) {
            const { matcher, render } = factory.registrations[matcherIndex];
            return render({ ...rest, cLocation, matcher });
        }
        return renderer({ cLocation, matcher: '', ...rest });
    };

    factory.registrations = [];

    factory.register = (matcher, render) => {
        if (Array.isArray(matcher)) {
            matcher.forEach((single) => factory.registrations.push({ matcher: single, render }));
        } else {
            factory.registrations.push({ matcher, render });
        }
    };

    return factory;
};
