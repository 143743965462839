import React, { lazy } from 'react';
const CashInfoApp = lazy(() => import('./clients/cashinfo/CashInfoApp'));
const VapolinkApp = lazy(() => import('./clients/vapolink/VapolinkApp'));
const WikiPapaApp = lazy(() => import('./clients/wikipapa/WikiPapaApp'));
const FluizyApp = lazy(() => import('./clients/fluizy/FluizyApp'));
const IdelinkApp = lazy(() => import('./clients/idelink/IdelinkApp'));
const IrritalkApp = lazy(() => import('./clients/irritalk/IrritalkApp'));
const LeolinkApp = lazy(() => import('./clients/leolink/LeolinkApp'));
const MidaslinkApp = lazy(() => import('./clients/midaslink/MidaslinkApp'));
const OxylinkApp = lazy(() => import('./clients/oxylink/OxylinkApp'));
const ETribuApp = lazy(() => import('./clients/sylviethiriez/ETribuApp'));
const Auto5LinkApp = lazy(() => import('./clients/auto5link/Auto5LinkApp'));
const OBlinkApp = lazy(() => import('./clients/oblink/OBlinkApp'));
const FootKornerLinkApp = lazy(() => import('./clients/footkornerlink/FootKornerLinkApp'));
const AcuitislinkApp = lazy(() => import('./clients/acuitislink/AcuitislinkApp'));
const EngagedApp = lazy(() => import('./clients/engaged/EngagedApp'));

export enum IdelinkCustomerApp {
	IDELIEN = 'IDELIEN',
	DEMOLINK = 'DEMOLINK',
	OXYLINK = 'OXYLINK',
	MIDASLINK = 'MIDASLINK',
	FLUIZY = 'FLUIZY',
	IRRITALK = 'IRRITALK',
	SYLVIETHIRIEZ = 'SYLVIETHIRIEZ',
	LEOLINK = 'LEOLINK',
	VAPOLINK = 'VAPOLINK',
	WIKIPAPA = 'WIKIPAPA',
	CASHINFO = 'CASHINFO',
	AUTO5LINK = 'AUTO5LINK',
	OBLINK = 'OBLINK',
	FOOTKORNERLINK = 'FOOTKORNERLINK',
	ACUITISLINK = 'ACUITISLINK',
	ENGAGED = 'ENGAGED',
}

//PGU TODO: J'ai été obligé de mettre chaque app en lazy loading. Sans ça toutes les apps sont chargées pour chaque client.
// ça pose bien évidemment un problème de performance.
// mais ça pose aussi un problème de style puisque les fichier css de toutes les app sont chargés pour chaque client.
// l'idéal serait d'avoir un fichier index.tsx différent pour chaque client.  >>> TODO!
export class IdelinkLauncher {
	public static getApp = (app: IdelinkCustomerApp | '__APP__'): React.ComponentType => {
		switch (app) {
			case IdelinkCustomerApp.DEMOLINK:
			case IdelinkCustomerApp.IDELIEN:
				return IdelinkApp;
			case IdelinkCustomerApp.MIDASLINK:
				return MidaslinkApp;
			case IdelinkCustomerApp.FLUIZY:
				return FluizyApp;
			case IdelinkCustomerApp.OXYLINK:
				return OxylinkApp;
			case IdelinkCustomerApp.IRRITALK:
				return IrritalkApp;
			case IdelinkCustomerApp.SYLVIETHIRIEZ:
				return ETribuApp;
			case IdelinkCustomerApp.LEOLINK:
				return LeolinkApp;
			case IdelinkCustomerApp.VAPOLINK:
				return VapolinkApp;
			case IdelinkCustomerApp.WIKIPAPA:
				return WikiPapaApp;
			case IdelinkCustomerApp.CASHINFO:
				return CashInfoApp;
			case IdelinkCustomerApp.AUTO5LINK:
				return Auto5LinkApp;
			case IdelinkCustomerApp.OBLINK:
				return OBlinkApp;
			case IdelinkCustomerApp.FOOTKORNERLINK:
				return FootKornerLinkApp;
			case IdelinkCustomerApp.ACUITISLINK:
				return AcuitislinkApp;
			case IdelinkCustomerApp.ENGAGED:
				return EngagedApp;
		}
		return IdelinkApp;
	};
}
