import { Configuration } from '@azure/msal-browser';
import Axios from 'axios';
import { withFuncFactory } from '../Factory';

const isProd = () => process.env.NODE_ENV === 'production';
const isDev = () => process.env.NODE_ENV === 'development';

//#region //* Config MODELS

export interface EnvironmentConfig {
    development: string;
    production: string;
}

export interface ApiConfig {
    url: EnvironmentConfig;
    client?: ClientConfig;
}

export interface ClientConfig {
    id: string;
    secret: string;
}

export interface ActiveDirectoryConfig {
    readonly clientID_development: string;
    readonly clientID_production: string;
    readonly authority_development: string;
    readonly authority_production: string;
    readonly graphScopes: Array<string>;
    readonly redirect_uri_development: string;
    readonly redirect_uri_production: string;
}

export interface ElasticSearchConfig {
    clientId: string;
    code: string;
    domain: string;
    uri: string;
    elasticSearchIndex: EnvironmentConfig;
}

//#endregion

export const DefaultProductionConfigPlaceholder = {
    VERSION: '__APP_VERSION__',
    API_DOMAIN: '__API_HOST__',
    GOOGLE_MAP_API_KEY: '__GOOGLE_MAP_API_KEY__',
    GOOGLE_CLIENT_ID: '__GOOGLE_CLIENT_ID__',
    ELASTIC_SEARCH_INDEX: '__ELASTIC_SEARCH_INDEX__',
    AD_CLIENT_ID: '__AD_CLIENT_ID__',
    AD_AUTH: '__AD_AUTH__',
    AD_REDIRECT_URI: '__AD_REDIRECT_URI__',
};

class DefaultConfigs {
    public static VERSION: EnvironmentConfig = {
        development: 'development',
        production: DefaultProductionConfigPlaceholder.VERSION,
    };

    public static CLIENT: ClientConfig = {
        id: 'FLUIZY_WEB_BACK',
        secret: 'Ideine',
    };

    public static API_DOMAIN: EnvironmentConfig = {
        production: DefaultProductionConfigPlaceholder.API_DOMAIN,
        development: 'https://idelink-fluizy-api-dev.azurewebsites.net',
    };

    public static GOOGLE_MAP_API_KEY: EnvironmentConfig = {
        production: DefaultProductionConfigPlaceholder.GOOGLE_MAP_API_KEY,
        development: 'AIzaSyDBoFqPkV-PaCbJWQl8lc-zVHcvMrnAd30',
    };

    public static GOOGLE_CLIENT_ID: EnvironmentConfig = {
        production: DefaultProductionConfigPlaceholder.GOOGLE_CLIENT_ID,
        development: '848842830886-5d21p5mpt4cmegg9a16b07iqldu6lclc.apps.googleusercontent.com',
    };

    public static ACTIVE_DIRECTORY_CONFIG: ActiveDirectoryConfig = {
        clientID_development: '258bf14f-1a1b-4e1b-a0ea-546599f64fdc',
        clientID_production: DefaultProductionConfigPlaceholder.AD_CLIENT_ID,
        authority_development: 'https://login.microsoftonline.com/d5f60f8f-e0c9-4f60-9e4e-46d36ec509ca',
        authority_production: DefaultProductionConfigPlaceholder.AD_AUTH,
        graphScopes: ['user.read'],
        redirect_uri_development: 'http://localhost:3000',
        redirect_uri_production: DefaultProductionConfigPlaceholder.AD_REDIRECT_URI,
    };

    public static LOGS: ElasticSearchConfig = {
        code: 'fIU59n4QGM0OE7UVfQQLrVkwgmygDragYTGLZR1CqVetA6bPSj0Gnw==',
        clientId: 'default',
        domain: 'https://idelink-functions-app.azurewebsites.net',
        uri: '/api/IdelinkMobileAnalytics',
        elasticSearchIndex: {
            development: 'fluizy-mobile-dev',
            production: DefaultProductionConfigPlaceholder.ELASTIC_SEARCH_INDEX,
        },
    };
}

//const getApiModuleNames = (roots: {}) => roots || { ['PackagedOrders']: 'packagedorders' };;

const getCurrentEnv = () => (isDev() ? 'local' : '__ENV__');

export const getClient = (): ClientConfig => DefaultConfigs.CLIENT;
export const getRoot = ({ development, production }: EnvironmentConfig = DefaultConfigs.API_DOMAIN) =>
    isProd() ? production : development;
export const getGoogleClientId = ({ development, production }: EnvironmentConfig = DefaultConfigs.GOOGLE_CLIENT_ID) =>
    isProd() ? production : development;
export const getGoogleMapApiKey = (
    { development, production }: EnvironmentConfig = DefaultConfigs.GOOGLE_MAP_API_KEY
) => (isProd() ? production : development);
export const getVersion = ({ development, production }: EnvironmentConfig = DefaultConfigs.VERSION) =>
    isProd() ? production : development;
export const getLogsConfig = (
    { domain, uri, code, clientId, elasticSearchIndex }: ElasticSearchConfig = DefaultConfigs.LOGS
) => ({
    domain: `${domain}${uri}?code=${code}&clientId=${clientId}`,
    elasticSearchIndex: isProd() ? elasticSearchIndex.production : elasticSearchIndex.development,
});

export const getActiveDirectoryConfig = (adConfig: ActiveDirectoryConfig = DefaultConfigs.ACTIVE_DIRECTORY_CONFIG) => {
    return {
        msalConfig: {
            auth: {
                clientId: isProd() ? adConfig.clientID_production : adConfig.clientID_development,
                authority: isProd() ? adConfig.authority_production : adConfig.authority_development,
                redirectUri: isProd() ? adConfig.redirect_uri_production : adConfig.redirect_uri_development,
                postLogoutRedirectUri: isProd() ? adConfig.redirect_uri_production : adConfig.redirect_uri_development,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                allowNativeBroker: false,
            },
        } as Configuration,
        graphScopes: adConfig.graphScopes,
    };
};

/** Make the project api init easier */
export const initApiConfig = (
    apiConfig?: ApiConfig,
    esLogsConfig?: ElasticSearchConfig,
    googleMapApiKeyConfig?: EnvironmentConfig
) => {
    if (apiConfig) {
        ConfigFactory.getRoot.register('**', () => getRoot(apiConfig.url));
        Axios.defaults.baseURL = getRoot(apiConfig.url);

        ConfigFactory.getClient.register('**', () => apiConfig.client);
    }

    if (googleMapApiKeyConfig) {
        ConfigFactory.getGoogleMapApiKey.register('**', () => getGoogleMapApiKey(googleMapApiKeyConfig));
    }

    if (esLogsConfig) {
        ConfigFactory.getLogsConfig.register('**', () => getLogsConfig(esLogsConfig));
    }
};

export const ConfigFactory = {
    getVersion: withFuncFactory(getVersion),
    getClient: withFuncFactory(getClient),
    getRoot: withFuncFactory(getRoot),
    getCurrentEnv: withFuncFactory(getCurrentEnv),
    getGoogleClientId: withFuncFactory(getGoogleClientId),
    getGoogleMapApiKey: withFuncFactory(getGoogleMapApiKey),
    getLogsConfig: withFuncFactory(getLogsConfig),
    getActiveDirectoryConfig: withFuncFactory(getActiveDirectoryConfig),
};
