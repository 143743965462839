import { ConfigFactory } from '../configs/ApiConfig';

export class EnvironmentHelper {

    public static isDev (): boolean {
        const processEnv = process.env.NODE_ENV || '';
        const devEnvironment: Array<string> = ['dev'];
        return EnvironmentHelper.isLocalDev()
            || devEnvironment.includes(processEnv)
            || devEnvironment.includes(ConfigFactory.getCurrentEnv());
    }

    public static isLocalDev (): boolean {
        const processEnv = process.env.NODE_ENV || '';
        const devEnvironments: Array<string> = ['local', 'development'];
        return devEnvironments.includes(processEnv);
    }
}