import './index.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { IdelinkCustomerApp, IdelinkLauncher } from './IdelinkLauncher';
import { EnvironmentHelper } from './libs/IdeLink.Web.Common/src/Helpers/EnvironmentHelper';

if (process.env.NODE_ENV !== 'production') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React);
}

export const appName = EnvironmentHelper.isLocalDev() ? IdelinkCustomerApp.DEMOLINK : '__APP__';
const App = IdelinkLauncher.getApp(appName);

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<></>}>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);
